<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Criminal Records</div>
        <div class="col text-end">
            <button v-if="clearbtnflag" type="button" class="btn custom-view-detail-btn"
                @click="searchvoterclearbtn()">Clear All
            </button>
        </div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);" v-if="!mobiledetailshowflag">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Name<span class="text-danger">*</span></label>
                            <div class="custom-form-group">
                                <input type="text" :disabled="btnloader" v-model="firstname"
                                    class="form-control text-capitalize" placeholder="Enter Name" maxlength="20"
                                    @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                <div class="custom-error" v-if="v$.firstname.$error">
                                    {{ v$.firstname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Father Name</label>
                            <div class="custom-form-group">
                                <input type="text" :disabled="btnloader" v-model="fathername"
                                    class="form-control text-capitalize" placeholder="Enter Father Name" maxlength="20"
                                    @keypress="isLetterWithSpace($event)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">State</label>
                            <Multiselect v-model="state" :disabled="btnloader" :options="stateList" :searchable="true"
                                label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                                :canClear="true" :object="true" :closeOnSelect="true" />
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Case</label>
                            <Multiselect v-model="casename" :disabled="btnloader" :options="casenameList"
                                :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Source Choice</label>
                            <Multiselect v-model="sourceform" :disabled="btnloader" :options="sourceList"
                                :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Year</label>
                            <div class="custom-form-group">
                                <input type="text" :disabled="btnloader" v-model="yearfrom"
                                    class="form-control text-capitalize" placeholder="Enter Year" maxlength="4"
                                    @keypress="onlyNumber($event)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Address</label>
                            <div class="custom-form-group">
                                <input type="text" :disabled="btnloader" v-model="addressform" class="form-control"
                                    placeholder="Enter Address" maxlength="250" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Case Category</label>
                            <Multiselect v-model="casecategory" :disabled="btnloader" :options="casecategoryList"
                                :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Case Type</label>
                            <Multiselect v-model="casetype" :disabled="btnloader" :options="casetypeList"
                                :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Search Choice</label>
                            <Multiselect v-model="searchchoice" :disabled="btnloader" :options="searchchoiceList"
                                :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="header-btn-outer btn btn-primary me-2"
                            @click="searchcasedetailbtn()" style="width: 104px;">
                            <span v-if="!btnloader">Get Details</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="btnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="voter-exit-box-outer mt-3 pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="" v-if="mobiledetailshowflag">
        <div class="custom-ultima-datatable horizental-scroll-height" style="height: calc(100vh - 141px)">
            <DataTable :value="courtcaseList" showGridlines :scrollable="true" scrollHeight="flex" columnResizeMode="fit" :paginator="true" :lazy="true"
                :rowHover="true"  filterDisplay="row" :totalRecords="totalRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :loading="loading">
                <template #empty>No Records found.</template>
                <template #loading>Loading data. Please wait...</template>
                <Column field="name" header="Name" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.name ? data.name : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="name" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="case_status" header="Case Status" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.case_status ? data.case_status : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="case_status" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="fir_no" header="FIR No." style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.fir_no ? data.fir_no : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="fir_no" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="under_acts" header="Under Acts" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.under_acts ? data.under_acts : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="under_acts" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="under_sections" header="Under Sections" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div style="word-break: break-word;">{{ data.under_sections ? data.under_sections : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="under_sections" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="court_code" header="Court Code" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.court_code ? data.court_code : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="court_code" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="court_name" header="Court Name" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div>{{ data.court_name ? data.court_name : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="court_name" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="case_code" header="Case Code" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.case_code ? data.case_code : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="case_code" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="uniq_case_id" header="Uniq Case Id" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div style="word-break: break-word;">{{ data.uniq_case_id ? data.uniq_case_id : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="uniq_case_id" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="state_code" header="State Code" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.state_code ? data.state_code : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="state_code" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="fir_year" header="FIR Year" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.fir_year ? data.fir_year : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="fir_year" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="case_year" header="Case Year" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.case_year ? data.case_year : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="case_year" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="case_category" header="Case Category" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.case_category ? data.case_category : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="case_category" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="oparty" header="Oparty" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div>{{ data.oparty ? data.oparty : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="oparty" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="raw_address" header="Raw Address" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div>{{ data.raw_address ? data.raw_address : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="raw_address" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address" header="Address" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div>{{ data.address ? data.address : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address_street" header="Address Street" style="min-width: 300px;max-width: 300px;">
                    <template #body="{ data }">
                        <div>{{ data.address_street ? data.address_street : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address_street" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address_taluka" header="Address Taluka" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.address_taluka ? data.address_taluka : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address_taluka" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="dist_code" header="Dist Code" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.dist_code ? data.dist_code : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="dist_code" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address_district" header="Address District" style="min-width: 400px;max-width: 400px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.address_district ? data.address_district : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address_district" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address_state" header="Address State" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.address_state ? data.address_state : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address_state" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="address_wc" header="Address wc" style="min-width: 120px;max-width: 120px;">
                    <template #body="{ data }">
                        <div>{{ data.address_wc ? data.address_wc : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="address_wc" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="state_name" header="State Name" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.state_name ? data.state_name : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="state_name" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="source" header="Source" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.source ? data.source : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="source" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="name_wc" header="Name wc" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.name_wc ? data.name_wc : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="name_wc" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="business_category" header="Business Category" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div class="text-capitalize">{{ data.business_category ? data.business_category : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="business_category" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
                <Column field="cnr" header="CNR" style="min-width: 200px;max-width: 200px;">
                    <template #body="{ data }">
                        <div>{{ data.cnr ? data.cnr : "N/A" }}</div>
                    </template>
                    <template #filter="{ }">
                        <div class="d-flex custom-form-group mb-0">
                            <input type="text" v-model="cnr" class="form-control" @keydown.enter="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" placeholder="Search"  />
                            <button type="button" @click="filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district)" class="data-table-filter btn ms-2" title="Filter">
                                <i class="pi pi-filter data-table-filter-icon" aria-hidden="true"></i>
                            </button>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            uancardnumber: '',
            erruan: '',
            errmsg: '',
            errStatus: false,
            showloader: false,
            CourtCaseTabDetails: [],
            mobiledetailshowflag: false,
            inputdisabled: false,
            sendotpbtnhide: false,
            nodatafoundflag: false,

            firstname: '',
            fathername: '',
            state: '',
            casename: '',
            sourceform: '',
            yearfrom: '',
            addressform: '',
            casecategory: '',
            casetype: '',
            searchchoice: '',
            stateList: [],
            casenameList: [
                { value: "1", label: "IPAP" },
                { value: "2", label: "Womwn Safety" },
                { value: "3", label: "PMLA" },
                { value: "4", label: "Police" },
                { value: "5", label: "Motor" },
            ],
            sourceList: [
                { value: "1", label: "Arrested" },
                { value: "2", label: "Consumer court" },
                { value: "3", label: "Defaulters" },
                { value: "4", label: "Ecourt" },
                { value: "5", label: "FIR" },
                { value: "6", label: "High Court" },
                { value: "7", label: "IBC" },
                { value: "8", label: "Ministry of Corporate Affairs" },
                { value: "9", label: "Supreme Court" },
                { value: "10", label: "Tribunals" },
            ],
            casecategoryList: [
                { value: "1", label: "Criminal" },
                { value: "2", label: "Civil" },
            ],
            casetypeList: [
                { value: "1", label: "Petitioner" },
                { value: "2", label: "Respondent" },
            ],
            searchchoiceList: [
                { value: "1", label: "Entity" },
                { value: "2", label: "Individual" },
            ],
            btnloader: false,
            courtcaseList: [],
            totalRecords: 0,
            loading: false,
            page_no: 0,
            clearbtnflag: '',
            caseid: '',
            address: '',
            address_state: '',
            address_taluka: '',
            source: '',
            state_name: '',
            under_sections: '',
            address_wc: '',
            fir_year: '',
            name_wc: '',
            business_category: '',
            uniq_case_id: '',
            case_year: '',
            case_category: '',
            dist_code: '',
            fir_no: '',
            state_code: '',
            case_code: '',
            court_code: '',
            cnr: '',
            oparty: '',
            raw_address: '',
            address_street: '',
            name: '',
            case_status: '',
            court_name: '',
            under_acts: '',
            address_district: '',
            items_per_page: 30,
        };
    },
    validations() {
        return {
            firstname: {
                required: helpers.withMessage("Please enter name", required),
            },
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        uancardnumber(uancardnumber) {
            this.errmsg = "";
            this.validateuanCardNumber(uancardnumber);
            if (uancardnumber.length == 0) {
                this.erruan = "";
                this.errStatus = false;
            }
        },
    },
    mounted() {
        this.getstates();
    },
    methods: {
        filterCallback(address, address_state, address_taluka, source, state_name, under_sections, address_wc, fir_year, name_wc, business_category, uniq_case_id, case_year, case_category, dist_code, fir_no, state_code, case_code, court_code, cnr, oparty, raw_address, address_street, name, case_status, court_name, under_acts, address_district) {
            this.loading = true;
            this.address = address;
            this.address_state = address_state;
            this.address_taluka = address_taluka;
            this.source = source;
            this.state_name = state_name;
            this.under_sections = under_sections;
            this.address_wc = address_wc;
            this.fir_year = fir_year;
            this.name_wc = name_wc;
            this.business_category = business_category;
            this.uniq_case_id = uniq_case_id;
            this.case_year = case_year;
            this.case_category = case_category;
            this.dist_code = dist_code;
            this.fir_no = fir_no;
            this.state_code = state_code;
            this.case_code = case_code;
            this.court_code = court_code;
            this.cnr = cnr;
            this.oparty = oparty;
            this.raw_address = raw_address;
            this.address_street = address_street;
            this.name = name;
            this.case_status = case_status;
            this.court_name = court_name;
            this.under_acts = under_acts;
            this.address_district = address_district;
            this.getcriminalrecordsfilterload({ 
                id: this.caseid, 
                page_no: this.page_no,
                items_per_page: this.items_per_page,
                address: this.address,
                address_state: this.address_state,
                address_taluka: this.address_taluka,
                source: this.source,
                state_name: this.state_name,
                under_sections: this.under_sections,
                address_wc: this.address_wc,
                fir_year: this.fir_year,
                name_wc: this.name_wc,
                business_category: this.business_category,
                uniq_case_id: this.uniq_case_id,
                case_year: this.case_year,
                case_category: this.case_category,
                dist_code: this.dist_code,
                fir_no: this.fir_no,
                state_code: this.state_code,
                case_code: this.case_code,
                court_code: this.court_code,
                cnr: this.cnr,
                oparty: this.oparty,
                raw_address: this.raw_address,
                address_street: this.address_street,
                name: this.name,
                case_status: this.case_status,
                court_name: this.court_name,
                under_acts: this.under_acts,
                address_district: this.address_district,
            });
        },
        getcriminalrecordsfilterload(e) {
            this.ApiService.getcriminalrecords(e).then((data) => {
                if (data.success === true) {
                    this.courtcaseList = data.records;
                    this.totalRecords = data.totalcount;
                    this.mobiledetailshowflag = true;
                    this.clearbtnflag = true;
                    this.loading = false;
                } else {
                    this.courtcaseList = null;
                    this.totalRecords = 0;
                    this.mobiledetailshowflag = false;
                    this.nodatafoundflag = true;
                    this.loading = false;
                }
            });
        },
        searchcasedetailbtn() {
            this.loading = true;
            this.v$.$validate();
            let fields = {};
            fields["name"] = this.firstname;
            if (this.fathername) {
                fields["father_name"] = this.fathername;
            }

            if (this.sourceform) {
                fields["source"] = this.sourceform.label;
            }
            if (this.state) {
                fields["state_name"] = this.state.label;
            }
            if (this.searchchoice) {
                fields["search_type"] = this.searchchoice.label;
            }
            if (this.casecategory) {
                fields["category"] = this.casecategory.label;
            }
            if (this.yearfrom) {
                fields["year"] = this.yearfrom;
            }
            if (this.addressform) {
                fields["address"] = this.addressform;
            }
            if (!this.v$.$error) {
                this.btnloader = true;
                this.ApiService.getCourtDetails(fields).then((data) => {
                    if (data.status == true) {
                        
                        this.caseid = data.id;
                        this.getcriminalrecords(this.caseid);
                    }
                    else {
                        this.btnloader = false;
                        this.nodatafoundflag = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        getcriminalrecords(e) {
            this.ApiService.getcriminalrecords({ id: e, page_no: this.page_no, items_per_page: this.items_per_page }).then((data) => {
                if (data.success === true) {
                    this.courtcaseList = data.records;
                    this.totalRecords = data.totalcount;
                    this.mobiledetailshowflag = true;
                    this.clearbtnflag = true;
                    this.loading = false;
                    this.btnloader = false;
                } else {
                    this.courtcaseList = null;
                    this.totalRecords = 0;
                    this.mobiledetailshowflag = false;
                    this.nodatafoundflag = true;
                    this.loading = false;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcriminalrecords({ id: this.caseid, page_no: this.page_no, items_per_page: this.items_per_page });
        },
        getstates() {
            this.ApiService.getstates().then((data) => {
                if (data.success == true) {
                    this.stateList = data.data;
                } else {
                    this.stateList = null;
                }
            });
        },
        validateuanCardNumber(val) {
            this.errStatus = false;
            if (!this.validUAN(val)) {
                this.erruan = "Please enter valid UAN number";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.erruan = "";
                return true;
            }
        },

        validUAN: function (e) {
            var re = /([0-9]){12}$/;
            return re.test(e);
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        isLetterWithSpace(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s.]+$/.test(char)) return true;
            else e.preventDefault();
        },
        searchvoterclearbtn() {
            this.firstname = '';
            this.fathername = '';
            this.state = '';
            this.casename = '';
            this.sourceform = '';
            this.yearfrom = '';
            this.addressform = '';
            this.casecategory = '';
            this.casetype = '';
            this.searchchoice = '';
            this.clearbtnflag = false;
            this.btnloader = false;
            this.mobiledetailshowflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}



.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 11px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 12px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 12px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 13px;
    word-wrap: break-word;
}

.dividerrowhide .p-divider {
    display: none;
}

.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}

.company-detail-box-outer {
    padding: 16px 16px 0;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}
</style>